@use '~@etchteam/mobius/src/styles/mixins/skin';
@use '~@etchteam/mobius/src/styles/variables/breakpoint';

$skins: light, dark, neutral, info, positive, negative, attention;

@include skin.create-skins($skins);

:root {
  --skin-background-muted-attention: var(--color-lemon);

  --color-collection: var(--color-orange);
  --color-disposal: var(--color-violet);
  --color-income: var(--color-forest);
}

@media (min-width: breakpoint.$tablet) {
  :root {
    --font-size-h1: 2.75em;
  }
}
